@import './scss/_mixins.scss';
@import './scss/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

html {
  scroll-behavior: smooth;
  font-family: 'Inter';
  font-style: normal;
}

.container {
  margin: 0 auto;
  max-width: $max-width;
  padding-left: 24px;
  padding-right: 24px;
}

.main {
  @include spacing($margin: 0, $padding: (64px 0 96px 0), $mobile-padding: (48px 0 96px 0));
}

.section-heading {
  @include text(32px, $color-black, 600, 38px);
}

.title,
.price {
  @include text(20px, $color-black, 600, 28px);
}

.primary-text {
  @include text(14px, $color-black, 400, 20px);
}

.subheading {
  @include text(18px, $color-black, 400, 27px);
}

.secondary-text {
  @include text(12px, $color-secondary-text, 400, 16px);
  text-transform: uppercase;
}

.secondary-heading {
  @include text(32px, $color-black, 600, 38.4px, 22px, $color-black, 600, 26.4px);
}

.icon-description {
  @include text(12px, $color-black, 400, 16px);
}

.cta {
  @include text(14px, $color-link, 400, 20px);
  @include cta();
}

.primary-heading {
  @include text(48px, $color-black, 700, 62px, 32px, $color-black, 700, 38.4px);

  @media screen and (max-width: $mobile-breakpoint) {
    text-align: center;
  }
}

.page {
  @include spacing($margin: 0, $padding: (64px 0px 96px 0px), $mobile-padding: (48px 0px 96px 0px));

  &.blurred {
    filter: blur(1px);

    /* Firefox version 34 and earlier */
    // filter: url("blur.svg#gaussian_blur");

    /* Webkit in Chrome 52, Safari 9, Opera 39, and earlier */
    -webkit-filter: blur(1px);
  }
}

.error-backend {
  margin-top: -15px;
}

.hero-image {
  width: 100%;
  max-width: 578px;

  @media screen and (max-width: $mobile-breakpoint) {
    max-width: 100%;
  }
}

.intro-card {
  @include card ($width: 100%, $border: none, $border-radius: 0px, $background-color: rgba(218, 251, 245, 0.2), $max-width: 0, $min-width: 0, $max-height: 0, $min-height: 0);
  @include spacing(0, (32px 0 45px 0px), 0, (48px 0 45px 0px));

  .in-partnership-with {
    @include text(14px, $color-cool-grey, 600, 21px);
  }
}

.partner-logo {
  @include image($width: 112px, $height: 112px, $mobile-width: 80px, $mobile-height: 80px);
  @include spacing($margin: (0 24px), $mobile-margin: (0 16px));
}

.partners-card {
  @include card ($width: 100%, $border: none, $border-radius: 0px, $background-color: white, $max-width: 0, $min-width: 0, $max-height: 0, $min-height: 0);
  padding: 64px 0;
}

.deals-card {
  background: url('/assets/images/deals-background.svg'), linear-gradient(0deg, rgba(240, 240, 240, 0.3), rgba(240, 240, 240, 0.3)), #FFFFFF;
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
  @include card ($width: 100%, $border: none, $border-radius: 0px, $max-width: 0, $min-width: 0, $max-height: 0, $min-height: 0);
  @include spacing(0, (96px 0px 96px 0), 0, (72px 0px 96px 0));
}

.sidebar-text {
  @include text(18px, $color-black, 400, 27px);
  border-left: 4px solid $color-primary;
  @include spacing($margin: (40px 0 0 0), $mobile-margin: (24px 0 0 0), $padding: (0 0 0 24px), $mobile-padding: (0 0 0 0));

  @media screen and (max-width: $mobile-breakpoint) {
    // border-top: 4px solid $color-primary;
    border-left: none;
    text-align: center;
  }

}

.offerings-card,
.checkout-card,
.post-payment-card {
  @include card ($width: 100%, $border: none, $border-radius: 0px, $background-color: white, $max-width: 0, $min-width: 0, $max-height: 0, $min-height: 0);

  .secondary-heading {
    @media screen and (max-width: $mobile-breakpoint) {
      text-align: center;
    }
  }
}

.logout-icon {
  font-size: 28px;
  color: $color-black;
}

.sticky-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  color: white;
  text-align: center;

  .elem {
    background-color: black;
    height: 50px;
  }
}