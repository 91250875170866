@import '../../scss/variables.scss';
@import '../../scss/mixins.scss';

.header {
  border-bottom: 1px solid $color-border;
  @include spacing(0, (24px 16px), 0, (24px 32px));

  @media screen and (max-width: $mobile-breakpoint) {
    border: none;
  }
}
