@import '../../scss/mixins';
@import '../../scss/variables';

.product-details-modal {
    .accordian {
        .accordian-title {
            @include text(16px, $color-black, 600, 24px);
            @include spacing((24px 0 0 0), 0,(24px 0 0 0), 0);
        }

        .item {
            @include card ($width: 100%, $border: none, $border-radius: 4px, $background-color: $color-background-tertiary, $max-width: 0, $min-width: 0, $max-height: 0, $min-height: 0);
            @include spacing((16px 0 0 0), (20px 20px), (16px 0 0 0), (20px 20px));
        }
    }
}