@import '../../scss/mixins';
@import '../../scss/variables';

.checkout-summary-card {
    @include spacing(0, (48px 32px), 0, (48px 32px));
    @include card($max-width: 373px);
    background: linear-gradient(0deg, rgba(201, 202, 205, 0.2), rgba(201, 202, 205, 0.2)), #FFFFFF;

    .summary-item {
        margin-bottom: 12px;
    }

    .spacing {
        @include spacing((0 0 30px 0), (20px 0 0 0), (0 0 30px 0), (20px 0 0 0));
    }

    .divider{
        @include divider();
    }
}