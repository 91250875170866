@import '../../scss/mixins';
@import '../../scss/variables';

.product-card {
    @include card ($width: 100%, $border: 1px solid $color-border, $border-radius: 8px, $background-color: white, $max-width: 572px);

    .divider {
        @include divider($margin: (8px 0 16px 0));
    }

    .description {
        @include text(14px, $color-secondary-text, 400, 20px);
        height: 60px;
    }

    .web {
        .image {
            @include image($width: 100%, $height: auto);
            object-fit: cover;
            border-radius: 8px 0 0 8px;
        }

        .content {
            @include spacing(0, (40px 24px 28px 24px),0,0);
        }

        .description {
            @include spacing((40px 0 0 0), 0,0,0);
        }
    }

    .mobile {
        @include spacing(0,0,0, (32px 24px 28px 24px));
    
        .image {
            @include image($width: 64px, $height: 64px);
            object-fit: cover;
        }

        .description {
            @include spacing(0,0,(16px 0 0 0), 0);
        }
    }
}
