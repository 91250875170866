@import '../../../scss/mixins';
@import '../../../scss/variables';

.tab-indicator {
  @include cta;
  @include text(18px, $color-cool-grey, 400, 21.6px);

  &.active {
    @include text(18px, $color-black, 600, 21.6px);
  }
}

.tab-indicator-pointer {
  margin: 0px 16px;
}