@import '../../scss/mixins';
@import '../../scss/variables';

.modal {

    .modal-body {
        @include spacing(0, (48px 64px), 0, (24px 32px));
    }

    .modal-title {
        @include text(20px, $color-black, 600, 28px);
    }

    .modal-divider {
        @include divider($margin: (16px 0 24px 0));
    }

    .modal-content {
        @include text(16px, $color-black, 400, 24px);
    }

    &.product-details-modal {
        .powered-by {
            @include text(14px, $color-black, 400, 20px);
            @include spacing((48px 0 0 0), 0,(48px 0 0 0), 0);
        }
    }
}