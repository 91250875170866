@import '../../scss/mixins';
@import '../../scss/variables';

.checkout-product-card {
    .image{
        @include image($width: 128px, $height: 128px, $mobile-width: 80px, $mobile-height: 80px);
        object-fit: cover;
    }

    .divider{
        @include divider($margin: (32px 0))
    }

    .description{
        height: 40px;
        overflow: clip;
    }
}