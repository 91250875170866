@import '../../scss/mixins';
@import '../../scss/variables';

.product-coupon-card {
    .label {
        @include text(14px, $color-cool-grey, 400, 20px);

        @media screen and (max-width: $mobile-breakpoint) {
            margin-top: 16px;
        }
    }

    .image {
        @include image($width: 128px, $height: 128px, $mobile-width: 80px, $mobile-height: 80px);
        object-fit: cover;
    }

    .content {
        @include text(18px, $color-black, 400, 22px);
    }

    .copy-code {
        @include text(14px, $color-link, 400, 20px);
    }

    .divider {
        @include divider($margin: (32px 0));
    }
}

.modal-card {
    padding: 16px;
    text-align: center;
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
    padding: 0;
}

.modal-stats {
    font-size: 24px;
    font-weight: 500;
    color: var(--primary-color);
}

.modal-icon {
    font-size: 50px;
    text-align: center;
    margin-top: 16px;
}

.modal-title {
    font-size: 20px;
    font-weight: 600;
    color: $color-black;
    line-height: 28px;
    // margin-top: 20px;
}

.member-modal {
    .product-name {
        font-size: 16px;
        font-weight: 500;
        color: $color-black;
        line-height: 24px;
    }
}


.modal-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: $color-red-input-error;
    line-height: 12px;
    margin: 8px 0 8px 0;
}

.avail-subscription-modal {
    @media screen and (max-width: $mobile-breakpoint) {
        max-width: 350px;
    }

    .content {
        padding: 16px;
    }

    .ant-modal-content {
        border-radius: 8px;
    }

    .coupon-code {
        padding: 18px;
        margin-bottom: 24px;
        border: 0.5px dashed var(--primary-color);
        background-color: #fafafa;
        width: max-content;

        .coupon-code-value {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: $color-primary-text;
        }

        .copy-coupon-code {
            margin-left: 10px;
            color: var(--primary-color);
        }
    }

    .subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }

    .steps {
        font-family: 'Inter', sans-serif !important;
        max-width: 512px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .m-divider {
        margin-top: 16px !important;
        margin-bottom: 24px !important;
    }


    .steps-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $color-primary-text;
        margin-bottom: 24px;
    }

    .steps>ol {
        margin: 4px !important;
        padding-left: 12px !important;
        // list-style-position: inside !important;
    }

    .steps>ol>li {
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 19px !important;
        color: #302D3C !important;
        margin: 0 0 2px 0 !important;
    }

    .generate-coupon {
        cursor: pointer;
        margin-bottom: 24px;
        padding-top: 16px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        height: 56px;
        width: 160px;
        background-image: url("../../assets/images/generate_coupon.svg");
        background-repeat: no-repeat;
        background-position: center;
        color: #4B5054;

    }

    @media screen and (max-width: 991px) {
        .steps {
            margin-top: 24px;
        }
    }
}