@import '../../scss/mixins';
@import '../../scss/variables';

.buy-product-card {
    @include card ($width: 100%, $border: 1px solid $color-border, $border-radius: 8px, $background-color: white, $max-width: 373px, $height: 620px);
    position: relative;

    .image {
        @include image($width: 100%, $height: 200px);
        object-fit: cover;
        border-radius: 8px 8px 0 0;
    }

    .content {
        @include spacing(0, (24px 24px 0 24px), 0, (24px 24px 0 24px));
    }

    .sub-title {
        @include text(12px, $color-cool-grey, 400, 16px);
        text-transform: uppercase;
    }

    .icon-wrapper {
        @include spacing((16px 0 0 0), 0, (16px 0 0 0), 0);

        .icon{
            text-align: center;
            margin-bottom: 4px; 
        }
    }

    .cta-wrapper{
        @include spacing(0, (16px 24px), 0, (16px 24px));
        position: absolute;
        bottom: 0;
    }
}