@import './scss/_mixins.scss';
@import './scss/_variables.scss';

.ant-modal-body {
    padding: 0px !important;
}

.ant-divider-horizontal {
    margin: 0px !important;
}

ul {
    margin: 0px !important;
    padding: 0px !important;
}

// Select
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid $color-cool-grey;

    &:hover {
        border: 1px solid $color-cool-grey;
    }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid $color-cool-grey;
}

.ant-select-item-option-active,
.ant-select-item-option-selected {
    background-color: $color-primary-lighter  !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: 1px solid $color-cool-grey;
}

.ant-form-item-label {
    padding: 0 !important;
    margin-bottom: 4px;
}

.ant-form-item {
    margin-bottom: 16px;
}

.ant-form-item-label>label {
    @include text(14px, $color-form-label, 400, 20px);
}

.ant-input {
    @include text(14px, $color-form-label, 400, 20px);
    @include spacing(0, (8px 0px 8px 12px), 0, (8px 0px 8px 12px));
    border-color: $color-tertiary-text;
    border-width: 1px;
    height: 36px;

    &:hover,
    &:focus {
        border-color: $color-primary  !important;
        border-radius: 4px;
        box-shadow: none;
    }

    .ant-input-status-error {
        box-shadow: none;
    }
}

.ant-spin-dot-item {
    background-color: $color-primary;
}

.ant-btn-primary {
    background-color: $color-primary;
    border-color: $color-primary;

    &:hover,
    &:active {
        background-color: $color-primary;
        border-color: $color-primary;
    }
}

.ant-notification-notice {
    background-color: $color-black;
}

.ant-notification-notice-close-icon,
.ant-notification-notice-description,
.ant-notification-notice-message,
.ant-notification-notice-icon-success {
    color: white !important;
}

.ant-form-item-required::before{
    display: none !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    line-height: 1;
    content: '*';
}