@import '../../scss/mixins';
@import '../../scss/variables';

.login-modal {
    @include card ($border: 1px solid $color-border, $border-radius: 8px, $background-color: white, $max-width: 480px);
    box-shadow: 0px 0px 32px rgba(62, 73, 70, 0.3);
    padding: 0;

    @media screen and (max-width: $mobile-breakpoint) {
        max-width: 350px;
    }

    .content {
        @include spacing(0, (72px 112px), 0, (48px));
    }
}